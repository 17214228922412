<template>
  <div class="wrap">
    <div class="main">
      <div class="box" v-for="item in list" :key="item.id">
        <div class="title">
          <span class="text">{{ item.goods_name }}</span>
          <span class="tip">{{ item.guige }}</span>
        </div>
        <div class="desc">
          <div class="info">
            <div class="label">
              <img src="@/assets/img/zzjf.png" alt="" class="icon">
              <span>{{ item.shoujia }}</span>
            </div>
          </div>
          <div class="sum">
            <van-stepper v-model="item.prePayNum" />
          </div>
          <div class="btn">
            <VanButton type="primary" block size="small" @click="handleClickItem(item)">兑换</VanButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import axios from 'axios';
import { showConfirmDialog, showNotify } from 'vant';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Fwtc',
  data() {
    return {
      action: 0,
      list: []
    }
  },
  created() {
    this.getData();
    this.get_user_detail({
      sjhm:this.$route.query.sfbs
    });
  },
  computed: {
    ...mapState(['user_detail']),
  },
  methods: {
    ...mapActions(['get_user_detail']),
    async getData() {
      let { data: res } = await axios.get('/api/api/get_goods_data', {
        params: {
          sjhm: this.$route.query.sjhm,
          sfbs: this.$route.query.sfbs
        }
      });
      if (res?.success) {
        res.data = res.data.map(m => ({ ...m, prePayNum: 1 }))
        this.list = res.data;
      }
    },
    handleClickItem(item) {
      if (this.user_detail.gzry == '1' || this.user_detail.mdgl == '1') {
        showConfirmDialog({
          title: '提示',
          message: `确认购买[${item.goods_name}]${item.prePayNum}${item.guige}?`,
          confirmButtonText: '确定'
        }).then(async res => {
          // console.log(res);
          let { data: result } = await axios.get('/api/api/goods_order', {
            params: {
              sjhm: this.$route.query.sjhm,
              sfbs: this.$route.query?.sfbs || '',
              goods_id: item.id,
              num: item.prePayNum || 1
            }
          })
          if (result?.success) {
            showNotify({
              type: 'success',
              message: result.msg || '购买成功'
            })

          } else {
            showNotify({
              type: 'danger',
              message: result.msg || '网络错误'
            })
          }
        })
      }
      else {
        this.$router.push({
          path: '/ddqr_normal',
          query: {
            sjhm: this.$route.query.sjhm,
            sfbs: this.$route.query?.sfbs || '',
            id: item.id,
            num: item.prePayNum || 1,
          }
        })
      }
    },
  }

}
</script>
<style lang="less" scoped>
.wrap {
  .main {
    margin-top: 24px;

    .box {
      margin: 12px;
      padding: 12px;
      background: #fff;
      border-radius: 10px;

      .title {
        font-size: 20px;
        color: #333;
        margin-bottom: 6px;

        .tip {
          font-size: 14px;
          color: #999;
          margin-left: 12px;
        }
      }

      .desc {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .info {
          display: flex;
          align-items: center;
          flex: 1;

          .label {
            display: flex;
            align-items: center;
            margin-right: 24px;

            .icon {
              width: 24px;
              height: 24px;
              margin-right: 6px;
            }

            span {
              font-size: 18px;
              color: #3fb6c6;
            }
          }
        }

        .btn {
          margin-left: 12px;
          width: 80px;
        }

      }
    }
  }
}
</style>
